import Layout from '@/components/Layouts/Full.vue';

export default {
  path: '/',
  component: Layout,
  // redirect: '/',
  children: [
    {
      path: '/product-create',
      component: () => import('@/views/inventory/product/ProductCreate'),
      name: 'productCreate',
      meta: {
        free: true,
        title: '',
        breadcrumb: [],
      },
    },
    {
      path: '/products',
      component: () => import('@/views/inventory/product/ProductList'),
      name: 'products',
      meta: {
        free: true,
        title: '',
        breadcrumb: [],
      },
    },
    {
      path: '/order-by-csv',
      component: () => import('@/views/inventory/product-csv/ProductByCsv'),
      name: 'orderByCsv',
      meta: {
        free: true,
        title: '',
        breadcrumb: [],
      },
    },
    {
      path: '/skus',
      component: () => import('@/views/inventory/sku/SkuList'),
      name: 'skus',
      meta: {
        free: true,
        title: '',
        breadcrumb: [],
      },
    },
  ]
};
