import axios from 'axios';
import router from '../router'

const headers = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

const instance = axios.create({
  baseURL: process.env.VUE_APP_URL,
  headers,
});
console.log('env: ' + process.env.VUE_APP_URL);
// Add a request interceptor
instance.interceptors.request.use(config => {
  if (config.url !== '/api/login') {
    // Set base url with version api
    config.baseURL = config.baseURL + '/api'
    // Set axios with token authorization
    config.headers['Authorization'] = localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token')
  }
  
  return config;
}, function (error) {
  console.log(error)
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  // console.log(response);
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  console.log(error)
  //refresh token
  const status = error.response ? error.response.status : null;

  if (status === 401) {
    router.push('/login')
  }

  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export default instance;
