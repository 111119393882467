import Layout from '@/components/Layouts/Full.vue';

export default {
  path: '/',
  component: Layout,
  // redirect: '/',
  children: [
    {
      path: '/dashboard',
      component: () => import('@/views/Dashboard/Dashboard'),
      name: 'dashboard',
      meta: {
        free: true,
        title: 'Reportes',
        breadcrumb: [],
      },
    },
    {
      path: '/prices',
      component: () => import('@/views/general/prices/PriceList'),
      name: 'prices',
      meta: {
        free: true,
        title: 'message.warehouse',
        breadcrumb: [],
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/user/UserListV2'),
      meta: {
        free: true,
        title: 'Usuarios'
      }
    },
    {
      path: '/customers',
      name: 'customers',
      component: () => import('@/views/general/entity/CustomersList'),
      meta: {
        free: true,
        title: 'Clientes'
      }
    },
    {
      path: '/categories',
      name: 'categories',
      component: () => import('@/views/general/Categories'),
      meta: {
        free: true,
        title: 'categories'
      }
    },
    {
      path: '/suppliers',
      name: 'suppliers',
      component: () => import('@/views/general/supplier/Suppliers'),
      meta: {
        free: true,
        title: 'Proveedores'
      }
    },
    {
      path: '/order-reports',
      name: 'reports',
      component: () => import('@/views/reports/orders/Orders'),
      meta: {
        free: true,
        title: 'Reportes de Ordenes'
      // path: '/reports',
      // name: 'reports',
      // component: () => import('@/views/general/reports/Reports'),
      // meta: {
      //   free: true,
      //   title: 'Reportes'
      }
    },
    {
      path: '/zones',
      name: 'zones',
      component: () => import('@/views/general/zone/ZoneList'),
      meta: {
        free: true,
        title: 'Zonas'
      }
    },
  ]
};
