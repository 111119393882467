/**
 * Vuely Global Components
 */
 
 // App Card component
import AppCard from './components/AppCard/AppCard';
import Loader from './components/commons/loaders/Loader';
import LoaderDotSpinner from './components/commons/loaders/LoaderDotSpinner';
import LoaderThreeBody from './components/commons/loaders/LoaderThreeBody';
import MyDialog from './components/commons/MyDialog';
  
const GlobalComponents = {
  install(Vue) {
    Vue.component('appCard', AppCard);
    Vue.component('Loader', Loader);
    Vue.component('LoaderDotSpinner', LoaderDotSpinner);
    Vue.component('LoaderThreeBody', LoaderThreeBody);
    Vue.component('MyDialog', MyDialog);
  },
};

export default GlobalComponents;
 