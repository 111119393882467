import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store';
import i18n from './i18n';

// global components
import GlobalComponents from './GlobalComponents';

// router
import router from './router';

//style
import './lib/TitanStyle';

Vue.config.productionTip = false

router.beforeEach((to, from , next) => {
  console.log(from.path)
  console.log(to.path)
  if (to.path === '/' && localStorage.getItem('user') !== null) {
    next({name: 'dashboard'})
  } else if (to.path === '/' && (localStorage.getItem('user') === null || localStorage.getItem('user') === undefined)) {
    next({name: 'login'})
  }
  next()
})

import Notifications from 'vue-notification'

Vue.use(GlobalComponents)
Vue.use(Notifications)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
  components: {
    App,
  },
}).$mount('#app')
