<template>
  <div>
    <Navbar/>
  </div>
</template>

<script>
import Navbar from '../Nav/Navbar.vue';
export default {
  components: {Navbar}
}
</script>

<style>

</style>