<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
  >
    <v-card>
      <v-card-title :class="`text-h5 ${configuration.typeDialog} mb-4`">
        <v-icon left v-if="configuration.iconTitle">{{ configuration.iconTitle }}</v-icon>
        {{ configuration.title }}
      </v-card-title>

      <v-card-text>
        {{ configuration.message }}
      </v-card-text>

      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="grey lighten-1"
          small
          @click="dialog = false"
        >
          No
        </v-btn>
        <v-btn
          color="primary"
          small
          @click="onYes"
        >
          Si
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data() {
      return {
        dialog: false,

        configuration: {
          iconTitle: '',
          title: '',
          message: '',
          typeDialog: '',
        },

        data: null,
      };
    },
    methods: {
      onYes() {
        this.$emit('onYes', this.data);
        this.dialog = false;
      },
    },
  }
</script>