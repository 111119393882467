<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" dark color="secondary" app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <v-icon left>mdi-domain</v-icon>
            {{ user.archon.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-icon left small>mdi-account-badge</v-icon>
            {{ user.name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list>
        <v-list-group
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            active-class="border"
            router :to="child.path"
          >
            <v-list-item-content>
              <v-list-item-title v-text="$t(child.title)"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="secondary" dark fixed app class="default-layout__navbar" extended extension-height="3">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="text-uppercase ">
        <span class="font-weight-light"><b>{{ $t(this.$route.meta.title) }}</b></span>

      </v-toolbar-title>

      <v-spacer/>

      <v-btn text @click="logOut" :disabled="loadingLogout">
        <span class="mr-2">
          Salir
        </span>
        <v-progress-circular
          v-if="loadingLogout"
          indeterminate
          color="white"
          :size="22"
        />
      </v-btn>
    </v-app-bar>

    <v-main class="default-layout__main-content ma-4">
      <transition name="slideInUp" >
        <router-view/>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
data: () => ({
    drawer: true,
    items: [
      {
        action: 'mdi-view-dashboard ',
        title: 'Reportes',
        items: [
          {
            title: 'General',
            path: '/dashboard'
          },
          {
            title: 'Ordenes',
            path: '/order-reports'
          },
        ]
      },
      {
        action: 'mdi-home-group ',
        title: 'Almacenes',
        active: true,
        items: [
          {
            title: 'Administrar',
            path: '/warehouses'
          },
          {
            title: 'Movimientos',
            path: '/movements'
          },
          {
            title: 'Transferencia',
            path: '/dispatch-create'
          },
          {
            title: 'Devolución',
            path: '/restore-create'
          },
        ],
      },
      {
        action: 'mdi-arrow-u-down-left-bold',
        title: 'Compras',
        items: [
          {
            title: 'Nuevo',
            path: '/buy-create'
          },
          {
            title: 'Lista',
            path: '/buy-list'
          },
        ],
      },
      {
        action: 'mdi-currency-usd',
        title: 'Ventas',
        items: [
          {
            title: 'Nuevo',
            path: '/sale-create'
          },
          {
            title: 'Lista',
            path: '/sale-list'
          },
        ],
      },
      {
        action: 'mdi-package',
        title: 'Productos',
        items: [
          {
            title: 'Nuevo',
            path: '/product-create'
          },
          {
            title: 'Lista',
            path: '/products'
          },
          {
            title: 'Skus',
            path: '/skus'
          },
        ],
      },
      {
        action: 'mdi-account-group',
        title: 'Relaciones',
        items: [
          {
            title: 'Clientes',
            path: '/customers'
          },
          {
            title: 'Proveedores',
            path: '/suppliers'
          }
        ]
      },
      {
        action: 'mdi-cogs',
        active: true,
        title: 'Configuraciones',
        items: [
          // {
          //   title: 'Precios',
          //   path: '/prices'
          // },
          {
            title: 'Usuarios',
            path: '/users'
          },
          {
            title: 'Categorias',
            path: '/categories'
          },
          {
            title: 'Paquetes',
            path: '/packages'
          },
          {
            title: 'Usuario - Almacen',
            path: '/user-warehouse'
          },
          {
            title: 'Zonas',
            path: '/zones'
          },
        ],
      },
    ],

    loadingLogout: false,
  }),
  components: {
    // TitanCard,
    // Logo,
  },
  methods: {
    ...mapActions({
      signOut: 'signOut',
    }),
    async logOut() {
      this.loadingLogout = true;
      await this.signOut();
      this.loadingLogout = false;
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
}
</script>

<style>
.border {
  border-left: 4px solid #FF414D;
  text-decoration-color: #FF414D;
};
.back {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url( 'https://cdn.pixabay.com/photo/2016/03/26/13/09/organic-1280537_1280.jpg') no-repeat center center;
  background-size: cover;
  background-color: red;
  transform: scale(1.1);
}
</style>