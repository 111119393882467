<template>
  <v-app>
    <router-view/>
    <notifications group="petition" />
  </v-app>
</template>

<script>
  // import Login from '@/components/auth/Login.vue';
  // import Layout from './components/Layouts/Layout.vue';
  export default {
    components: {
      // Login,
      // Layout,
    },

  }
</script>
<style scoped>
  /* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
