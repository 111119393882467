import Vue from "vue";
import VueRouter from "vue-router";

//All Routes
// import Accounting from './accounting';
import Warehouse from './warehouse';
import Inventory from './inventory';
import General from './general';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    // Accounting,
    Warehouse,
    Inventory,
    General,
    {
      path: '/login',
      name: 'login',
      component: () => import('@/components/auth/Login'),
      meta: {
        free: true
      }
    }
  ]
})

export default router