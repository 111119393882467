import Layout from '@/components/Layouts/Full.vue';

export default {
  path: '/',
  component: Layout,
  // redirect: '/',
  children: [
    {
      path: '/warehouses',
      component: () => import('@/views/warehouse/warehouse/WarehouseContainer'),
      name: 'warehouse',
      meta: {
        free: true,
        title: 'Almacenes',
        breadcrumb: [],
      },
    },
    {
      path: '/buy-list',
      component: () => import('@/views/warehouse/order/OrderBuyList'),
      name: 'buyList',
      meta: {
        free: true,
        title: 'Compras',
        breadcrumb: [],
      },
    },
    {
      path: '/movements',
      component: () => import('@/views/warehouse/order/OrderWarehouseList'),
      name: 'movements',
      meta: {
        free: true,
        title: 'Movimientos',
        breadcrumb: [],
      },
    },
    {
      path: '/dispatch-create',
      component: () => import('@/views/warehouse/order/OrderDispatchCreate'),
      name: 'orderDispatchCreate',
      meta: {
        free: true,
        title: 'Envio',
        breadcrumb: [],
      },
    },
    {
      path: '/restore-create',
      component: () => import('@/views/warehouse/order/OrderRestoreCreate'),
      name: 'orderRestoreCreate',
      meta: {
        free: true,
        title: 'Devolucion Inventario',
        breadcrumb: [],
      },
    },
    {
      path: '/buy-create',
      component: () => import('@/views/warehouse/order/OrderBuyCreate'),
      name: 'orderBuyCreate',
      meta: {
        free: true,
        title: 'Comprar',
        breadcrumb: [],
      },
    },
    {
      path: '/buy-edit/:orderId',
      component: () => import('@/views/warehouse/order/OrderBuyEdit'),
      name: 'orderBuyEdit',
      meta: {
        free: true,
        title: 'Editar compra',
        breadcrumb: [],
      },
    },
    {
      path: '/sale-create',
      component: () => import('@/views/warehouse/order/OrderSaleCreate'),
      name: 'orderSaleCreate',
      meta: {
        free: true,
        title: 'Venta',
        breadcrumb: [],
      },
    },
    {
      path: '/sale-list',
      component: () => import('@/views/warehouse/order/OrderSaleList'),
      name: 'saleList',
      meta: {
        free: true,
        title: 'Ventas',
        breadcrumb: [],
      },
    },
    {
      path: '/purchase-orders',
      component: () => import('@/views/warehouse/purchase-order/PurchaseOrderList'),
      name: 'purchaseOrders',
      meta: {
        free: true,
        title: 'Pedidos',
        breadcrumb: [],
      },
    },
    {
      path: '/packages',
      component: () => import('@/views/warehouse/package/PackageList'),
      name: 'packages',
      meta: {
        free: true,
        title: 'Paquetes',
        breadcrumb: [],
      },
    },
    {
      path: '/user-warehouse',
      component: () => import('@/views/warehouse/user-warehouse/MatchUserWarehouse'),
      name: 'userWarehouse',
      meta: {
        free: true,
        title: 'Almecenes - Usuario',
        breadcrumb: [],
      },
    },
  ]
};
