import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import router from '../router'
import api from '@/api'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  strict: debug,
  plugins: debug ? [createLogger()] : [],
  state: {
    user: JSON.parse(localStorage.getItem('user')) || null,
    access_token: localStorage.getItem('access_token') || null,
    token_type: localStorage.getItem('token_type') || null,
  },
  getters: {
    authenticated(state) {
      return state.authenticated
    },
    getUser: state => {
      return state.user;
    },
    getToken: state => {
      return state.access_token;
    },
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value
    },
    SET_USER(state, value) {
      state.user = value
    },
    setCredentials(state, value) {
      state.access_token = value.access_token;
      state.token_type = value.token_type;
    },
  },
  actions: {
    async signIn({ dispatch }, credentials) {
      const response = await api.post('/api/login', credentials)
      console.log(response.data);
      if (response.data.user.archons.length > 0) {
        const user = {
          id: response.data.user.id,
          name: response.data.user.name,
          email: response.data.user.email,
          archon: response.data.user.archons.length > 0 ? response.data.user.archons[0] : null,
          warehouse: null,
        };
        dispatch('setSession', {
          user,
          access_token: response.data.access_token,
          token_type: response.data.token_type,
        });
        return response;
      }
    },

    async signOut({ dispatch }) {
      try {
        await api.post('/logout')
        router.push('/login')
        return dispatch('eliminarSesion')
      } catch (error) {
        router.push('/login')
        return dispatch('eliminarSesion')
      }
    },

    setSession({ commit }, data) {
      localStorage.setItem('access_token', data.access_token)
      localStorage.setItem('token_type', data.token_type)
      localStorage.setItem('user', JSON.stringify(data.user))
      commit('SET_AUTHENTICATED', true)
      commit('SET_USER', data.user)
      commit('setCredentials', data)
    },

    eliminarSesion({ commit }) {
      localStorage.setItem('access_token', null)
      localStorage.setItem('token_type', null)
      localStorage.setItem('user', null)
      commit('SET_AUTHENTICATED', false)
      commit('SET_USER', null);
      commit('setCredentials', {
        access_token: null,
        token_type: null,
      });
    }
  },
});